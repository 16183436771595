<template>
  <Orbit msg="" />
  <h5>0.03.203 poc</h5>
</template>


<script>
//import HelloWorld from './components/HelloWorld.vue'
import Orbit from "../components/Orbit.vue";

export default {
  name: "App",
  components: {
    // HelloWorld
    Orbit,
  },
};
</script>
