<template>
  <CartesianOrbit msg="Orbit using CartesianOrbit Coords" />
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
import CartesianOrbit from "../components/CartesianOrbit.vue";

export default {
  name: "App",
  components: {
    CartesianOrbit,
  },
};
</script>
