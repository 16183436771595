<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
  </div>

  <canvas id="canvas" width="800" height="800" style="z-index: 0">
    your browser doesn't support html 5
  </canvas>
</template>

<script>
/* eslint-disable */
const LEAP_YEAR_ANGLE = 0.9836; // 360.0 / 366.0;
const YEAR_ANGLE = 0.9863; // 360.0 / 365.0;
const RADIUS = 330;
const PI_OVER_180 = Math.PI / 180; // 3.1_415_926_5358
const One80_OVER_PI = 180 / Math.PI;

var center_x = 0;
var center_y = 0;
var point_size = 3;
var font_size = "20px";

var arcVector = [];

export default {
  name: "Orbit",
  props: {
    msg: String,
  },

  mounted() {
    var c = document.getElementById("canvas");
    var ctx = c.getContext("2d");

    // this.vueCanvas = ctx;
    // console.log("mounted");
    var offsetFactor = 0;
    center_x = c.offsetWidth / 2 + offsetFactor;
    center_y = c.offsetHeight / 2 + offsetFactor;

    currYear = new Date().getFullYear();

    drawArcVector(ctx, arcVector);
    drawCalendar(ctx);

    c.addEventListener("mousedown", (evt) => downListener(c, evt));
    c.addEventListener("mousemove", (evt) => moveListener(c, ctx, evt));
    c.addEventListener("mouseup", (evt) => upListener(ctx, evt));
  },
};

let moved = false;
let down = false;
let startAngle;
let endAngle;
let currYear = 2022;
/* eslint-disable */
function dragArc(ctx, start, end) {
  ctx.clearRect(0, 0, 800, 800);
  //ctx.rotate(90); // Produces a very cool sliding effect
  ctx.beginPath();

  var start_deg = (radians2degrees(startAngle) + 450) % 360;
  var end_deg = (radians2degrees(endAngle) + 450) % 360;

  if (start_deg > end_deg) {
    // console.log("increment year: " + currYear++);
    ctx.arc(center_x, center_y, RADIUS + 10, start, end, true);
    ctx.arc(center_x, center_y, RADIUS - 20, end, start, false);
  } else {
    ctx.arc(center_x, center_y, RADIUS + 10, end, start, true);
    ctx.arc(center_x, center_y, RADIUS - 20, start, end, false);
  }
  ctx.fillStyle = "rgba(128, 185, 255, 0.5)"; // "blue"
  ctx.fill();
  ctx.closePath();
  ctx.stroke();
}

/* eslint-disable */
function downListener(c, evt) {
  moved = false;
  down = true;
  var pos = getMousePos(c, evt, "move");
  startAngle = Math.atan2(pos.y - center_y, pos.x - center_x);
}

/* eslint-disable */
let moveListener = (c, ctx, evt) => {
  moved = true;
  writeMouseCoords(c, evt);
  if (down) {
    let pos = getMousePos(c, evt);
    var currAngle = Math.atan2(pos.y - center_y, pos.x - center_x);

    endAngle = currAngle;
    dragArc(ctx, startAngle, endAngle);
    ctx.closePath();

    drawArcVector(ctx, arcVector);
    drawCalendar(ctx);
  }
};

let upListener = (ctx, evt) => {
  down = false;
  if (moved) {
    // ctx.globalCompositeOperation = 'source-over';
    ctx.stroke();
    arcVector.push(Object.freeze({ start: startAngle, end: endAngle }));
  }
};

function drawCalendar(ctx) {
  drawOrbitDays(ctx);
  drawOrbitMonths(ctx);
  drawOrbitMonthsLabels(ctx);
  drawCenterPoint(0, 0, currYear, ctx);
}

/* eslint-disable */
function GetPoint(ptCenter, nRadius, fAngle) {
  var x = Math.cos((2 * Math.PI * fAngle) / 360) * nRadius + ptCenter.x;
  var y = -Math.sin((2 * Math.PI * fAngle) / 360) * nRadius + ptCenter.y;
  return new Point(x, y);
}

/* eslint-disable */
function Point(x, y) {
  this.x = x;
  this.y = y;
}

/* eslint-disable */
function degreesToRadians(degree) {
  return PI_OVER_180 * degree;
}

// angle theta =  arc_len (s) / radians
/* eslint-disable */
function arclen2radians(arclen, radius) {
  return arclen / radius;
}

function radians2degrees(radians) {
  return radians * One80_OVER_PI;
}

// Get Day of Year
// https://stackoverflow.com/questions/8619879/javascript-calculate-the-day-of-the-year-1-366/44873576
// not sure I really like this but it's working for now
// probably should re-evaluate
Date.prototype.isLeapYear = function () {
  var year = this.getFullYear();
  if ((year & 3) != 0) return false;
  return year % 100 != 0 || year % 400 == 0;
};

Date.prototype.getDOY = function () {
  var dayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
  var mn = this.getMonth();
  var dn = this.getDate();
  var dayOfYear = dayCount[mn] + dn;
  if (mn > 1 && this.isLeapYear()) dayOfYear++;
  return dayOfYear;
};

var months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
/* eslint-disable */
function drawOrbitMonthsLabels(ctx) {
  ctx.fillStyle = "black";
  ctx.font = "bold 14px arial";

  ctx.moveTo(center_x + RADIUS - 10, center_y);
  var j = 0;
  var date = new Date(2022, 1, 0);
  for (var i = 0; i < 12; i++) {
    j = date.getDOY() - 1 - 15;
    var dotAngle = 90 - YEAR_ANGLE * j;
    var pt1 = GetPoint(new Point(center_x, center_y), RADIUS + 15, dotAngle);
    ctx.fillText(months[i], pt1.x - 8, pt1.y + 4);

    date.setMonth(date.getMonth() + 1);
  }
  ctx.stroke();
}
/* eslint-disable */
function drawOrbitMonths(ctx) {
  ctx.lineWidth = 1;
  //ctx.moveTo(center_x + RADIUS - 10, center_y);
  var j = 0;
  var date = new Date(2022, 1, 1);
  for (var i = 0; i < 12; i++) {
    j = date.getDOY() - 1;
    var dotAngle = 90 - YEAR_ANGLE * j;
    var pt1 = GetPoint(new Point(center_x, center_y), RADIUS + 10, dotAngle);
    var pt2 = GetPoint(new Point(center_x, center_y), RADIUS - 15, dotAngle);

    // Draw the Month line
    ctx.moveTo(pt1.x, pt1.y);
    ctx.lineTo(pt2.x, pt2.y);
    ctx.stroke();
    date.setMonth(date.getMonth() + 1);
  }
  //ctx.stroke();
}

// Here January is 1 based
//Day 0 is the last day in the previous month
// Here January is 0 based
// return new Date(year, month+1, 0).getDate();
var getDaysInMonth = function (month, year) {
  // Here January is 1 based
  //Day 0 is the last day in the previous month
  return new Date(year, month, 0).getDate();
  // Here January is 0 based
  // return new Date(year, month+1, 0).getDate();
};

/* eslint-disable */
function drawOrbitDays(ctx) {
  ctx.lineWidth = 0.5;
  ctx.moveTo(center_x + RADIUS - 10, center_y);
  for (var i = 1; i < 365; i++) {
    var radians = (Math.PI / 180) * i;
    var dotAngle = 90 - YEAR_ANGLE * i;
    //console.log("dotAngle: " + dotAngle + "   radians: " + radians);
    var pt1 = GetPoint(new Point(center_x, center_y), RADIUS, dotAngle);
    var pt2 = GetPoint(new Point(center_x, center_y), RADIUS - 10, dotAngle);
    ctx.moveTo(pt1.x, pt1.y);
    ctx.lineTo(pt2.x, pt2.y);
    ctx.stroke();
    //ctx.lineTo(center_x + radius,  center_x + radius + 10 )
    //ctx.lineTo( center_x + radius * Math.cos(radians), center_y + radius * Math.sin(radians) );
  }
  ctx.stroke();
}

/* eslint-disable */
function drawCircle(ctx) {
  // ctx.translate(center_x, center_y);
  ctx.beginPath();
  ctx.arc(center_x, center_y, RADIUS, 0, 0.2 * Math.PI);
  ctx.stroke();
}

/* eslint-disable */
function drawCenterPoint(angle, distance, label, ctx) {
  var x = center_x + RADIUS * Math.cos((-angle * Math.PI) / 180) * distance;
  var y = center_y + RADIUS * Math.sin((-angle * Math.PI) / 180) * distance;
  //ctx.clearRect(x, y, 3, 3);
  ctx.beginPath();
  ctx.arc(x, y, point_size, 0, 2 * Math.PI);
  //ctx.fill();
  ctx.closePath();

  // draw the label
  ctx.font = font_size;
  ctx.fillStyle = "black";
  ctx.fillText(label, x + 10, y);
}

/* eslint-disable */
function drawArc(ctx) {
  ctx.beginPath();
  ctx.arc(center_x, center_y, RADIUS - 10, 0, 1.5 * Math.PI, true);
  ctx.arc(center_x, center_y, RADIUS + 10, 1.5 * Math.PI, 0, false);
  ctx.fillStyle = "rgba(128, 185, 255, 0.5)"; // "blue"
  ctx.font = font_size;
  ctx.closePath();
  ctx.fill();
}

/* eslint-disable */
function drawArcSlice(ctx, start, end) {
  ctx.beginPath();
  if (start < end) {
    var tmp = start;
    start = end;
    end = tmp;
  }
  ctx.arc(center_x, center_y, RADIUS + 10, start, end, true);
  ctx.arc(center_x, center_y, RADIUS - 20, end, start, false);

  ctx.fillStyle = "rgba(128, 185, 255, 0.5)"; // "blue"
  ctx.font = font_size;
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
}

function drawArcVector(ctx, arcVector) {
  arcVector.forEach((element) => {
    drawArcSlice(ctx, element.start, element.end);
  });
}

function writeMouseCoords(canvas, evt) {
  var context = canvas.getContext("2d");
  var pos = getMousePos(canvas, evt);
  //      context.clearRect(0, 0, 80, 25);
  context.fillStyle = "white";
  context.clearRect(0, 0, 100, 70);
  context.fill();
  context.stroke();
  context.font = "10pt Calibri";
  //context.fillStyle = '#ffffff'; // or whatever color the background is.
  //context.fillText(message, 40, 25);
  context.fillStyle = "black";
  context.fillText("coords:", 10, 25);
  context.fillText("x: " + pos.x, 10, 37);
  context.fillText("y: " + pos.y, 10, 50);

  var start_deg = (radians2degrees(startAngle) + 360 + 90) % 360;
  var end_deg = (radians2degrees(endAngle) + 360 + 90) % 360;

  context.fillText("sa: " + start_deg, 10, 60);
  context.fillText("ea: " + end_deg, 10, 70);
  //console.log(message);
}

function getMousePos(canvas, evt) {
  var rect = canvas.getBoundingClientRect();
  return {
    x: evt.clientX - rect.left,
    y: evt.clientY - rect.top,
  };
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
