import { createApp } from "vue";
import App from "./App.vue";
import router from "./router"; // <---

//import Bootstrap from "bootstrap-vue/dist/bootstrap-vue.esm";
//import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
//Vue.config.productionTip = false;

//Vue.use(BootstrapVue);
//Vue.use(IconsPlugin);

const app = createApp(App).use(router).mount("#app");
