<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
  </div>

  <canvas id="canvas" width="800" height="800" style="z-index: 0">
    your browser doesn't support html 5
  </canvas>
</template>

<script>
/* eslint-disable */
const YEAR_ANGLE = 0.9863; // 360.0 / 365.0;
const RADIUS = 330;
const PI_OVER_180 = Math.PI / 180; // 3.1_415_926_5358
const One80_OVER_PI = 180 / Math.PI;

var center_x = 0;
var center_y = 0;
var point_size = 3;
var font_size = "20px";

let outerRadius = RADIUS + 10;
let innerRadius = RADIUS - 20;

var arcVector = [];

export default {
  name: "CartesianOrbit",
  props: {
    msg: String,
  },

  mounted() {
    var c = document.getElementById("canvas");
    var ctx = c.getContext("2d");
    var ctx_rotated = c.getContext("2d");

    // set the canvas origin (0,0) to center canvas
    // All coordinates to the left of center canvas are negative
    // All coordinates below center canvas are negative
    ctx_rotated.translate(canvas.width / 2, canvas.height / 2);
    var r = degreesToRadians(-90);
    ctx_rotated.rotate(r);

    // this.vueCanvas = ctx;
    // console.log("mounted");
    //    var offsetFactor = 0;
    //    center_x = c.offsetWidth / 2 + offsetFactor;
    //    center_y = c.offsetHeight / 2 + offsetFactor;
    center_x = 0;
    center_y = 0;

    drawCalendar(ctx);

    drawLine(ctx);

    c.addEventListener("mousedown", (evt) => downListener(c, ctx, evt));
    c.addEventListener("mousemove", (evt) => moveListener(c, ctx, evt));
    c.addEventListener("mouseup", (evt) => upListener(ctx, evt));
  },
};

let moved = false;
let down = false;
let startAngle;
let endAngle;

/* ##########################   MOUSE EVENTS ############################## */

/* eslint-disable */
function downListener(c, ctx, evt) {
  moved = false;
  down = true;
  var pos = getMousePos(c, ctx, evt);
  console.log("pos: (x,y) (" + pos.x + "," + pos.y + "");
  startAngle = Math.atan2(pos.y, pos.x);
  var start_deg = (radians2degrees(-startAngle) + 360) % 360;
  console.log("startAngle:  " + startAngle);
  console.log("startAngleDegrees:  " + start_deg);
}

/* eslint-disable */
function dragArc(ctx, start, end) {
  //ctx.rotate(90); // Produces a very cool sliding effect
  ctx.beginPath();

  start = start - degreesToRadians(-90);
  end = end - degreesToRadians(-90);
  var start_deg = (radians2degrees(start) + 360) % 360;
  var end_deg = (radians2degrees(end) + 360) % 360;

  if (start_deg > end_deg) {
    ctx.arc(center_x, center_y, outerRadius, start, end, true);
    ctx.arc(center_x, center_y, innerRadius, end, start, false);
  } else {
    ctx.arc(center_x, center_y, outerRadius, end, start, true);
    ctx.arc(center_x, center_y, innerRadius, start, end, false);
  }
  ctx.fillStyle = "rgba(128, 185, 255, 0.5)"; // "blue"
  ctx.fill();
  ctx.closePath();
  ctx.stroke();
}

/* eslint-disable */
let moveListener = (c, ctx, evt) => {
  moved = true;
  //let m = getMousePos(c, evt);
  //console.log("(" + m.x + "," + m.y + ")");
  writeMouseCoords(c, ctx, evt);
  if (down) {
    ctx.clearRect(-400, -440, 800, 800);
    writeMouseCoords(c, ctx, evt);
    //      ctx.globalCompositeOperation = 'destination-over';
    //console.log("--");
    let p3 = getMousePos(c, ctx, evt);
    endAngle = Math.atan2(p3.y, p3.x);
    dragArc(ctx, -startAngle, -endAngle);
    ctx.closePath();
  }

  drawCalendar(ctx);
};

let upListener = (ctx, evt) => {
  down = false;
  if (moved) {
    // ctx.globalCompositeOperation = 'source-over';
    ctx.stroke();
    arcVector.push(Object.freeze({ start: startAngle, end: endAngle }));
  }
};

/* ##########################   MOUSE EVENTS ############################## */

function drawCalendar(ctx) {
  drawCircle(ctx);
  drawCenterPoint(0, 0, "year", ctx);
  drawLine(ctx);
}

/* eslint-disable */
function degreesToRadians(degree) {
  return PI_OVER_180 * degree;
}

function radians2degrees(radians) {
  return radians * One80_OVER_PI;
}

/* eslint-disable */
function drawCircle(ctx) {
  // ctx.translate(center_x, center_y);
  ctx.beginPath();
  ctx.arc(
    center_x,
    center_y,
    RADIUS,
    degreesToRadians(-90),
    degreesToRadians(-80),
    false
  );
  ctx.stroke();
}

function drawLine(ctx) {
  ctx.beginPath();
  ctx.moveTo(RADIUS, 0);
  ctx.lineTo(0, 0);
  ctx.closePath();
}

function writeMouseCoords(c, ctx, evt) {
  //context.translate(canvas.width / 2, canvas.height / 2);

  var pos = getMousePos(c, ctx, evt);

  //      context.clearRect(0, 0, 80, 25);
  ctx.fillStyle = "white";
  ctx.clearRect(-1, -1, 100, 80);
  ctx.fill();
  ctx.stroke();
  ctx.font = "10pt Calibri";
  //context.fillStyle = '#ffffff'; // or whatever color the background is.
  //context.fillText(message, 40, 25);
  ctx.fillStyle = "black";
  ctx.fillText("coords:", 10, 25);
  ctx.fillText(" x: " + pos.x, 10, 37);
  ctx.fillText(" y: " + pos.y, 10, 50);

  var ninety = degreesToRadians(-270);
  var start_deg = (radians2degrees(-startAngle) + 360) % 360;
  var end_deg = (radians2degrees(-endAngle) + 360) % 360;

  ctx.fillText(" sa: " + start_deg, 10, 60);
  ctx.fillText(" ea: " + end_deg, 10, 70);
  //console.log(message);
}

function getMousePos(canvas, ctx, evt) {
  var rect = canvas.getBoundingClientRect();
  var pos = {
    x: evt.clientX - rect.left - canvas.width / 2,
    y: -(evt.clientY - rect.top - canvas.height / 2),
  };
  return pos;
}

/* eslint-disable */
function drawCenterPoint(angle, distance, label, ctx) {
  var x = center_x + RADIUS * Math.cos((-angle * Math.PI) / 180) * distance;
  var y = center_y + RADIUS * Math.sin((-angle * Math.PI) / 180) * distance;
  //ctx.clearRect(x, y, 3, 3);
  ctx.beginPath();
  ctx.arc(x, y, point_size, 0, 2 * Math.PI);
  //ctx.fill();
  ctx.closePath();

  // draw the label
  ctx.font = font_size;
  ctx.fillStyle = "black";
  ctx.fillText(label, x + 10, y);
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
