<template>
  <nav class="navbar navbar-dark bg-dark">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">Orbital Calendar</a>
      <router-link tag="button" to="/">
        <button type="button" class="btn btn-success">Default</button>
      </router-link>

      <router-link to="/cartesian">
        <button type="button" class="btn btn-danger" href="/cartesian">
          Cartesian
        </button>
      </router-link>

      <router-link to="/about">
        <button type="button" class="btn btn-info" href="/about">About</button>
      </router-link>
    </div>
  </nav>
  <!--
  </nav>
  -->
  <div>
    <router-view />
  </div>
</template>
<!-- 

<template>
  <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/>
  <Orbit msg="Welcome to Your Vue.js App"/>
</template>
-->


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
